<template>
  <div>
    <b-card>
      <b-card-body class="mb-0 pb-0">
        <p><b>Overall Count</b>: {{ report.report.overall_count }}</p>
        <div v-if="!dryRun">
          <p><b>Filename</b>: {{ report.filename }}</p>
          <p><b>Created</b>: {{ formatDate(report.created_at) }} UTC</p>
          <div v-if="report.was_quarantined">
            <p><b>Status</b>:
              <b-badge variant="success">Accepted</b-badge>
            </p>
          </div>
          <div v-if="report.quarantined">
            <p><b>Status</b>:
              <b-badge v-if="report.status === 'pending'" variant="warning">Pending</b-badge>
              <b-badge v-if="report.status === 'accepted'" variant="success">Accepted</b-badge>
              <b-badge v-if="report.status === 'rejected'" variant="danger">Rejected</b-badge>
            </p>
          </div>
          <b-row v-if="!blockActions">
            <b-col>
              <b>Queue Details</b>
            </b-col>
          </b-row>
          <b-row v-if="!blockActions">
            <b-col class="ml-3 mt-1 mb-3">
              <div v-if="report.queue">
                <p class="mb-0"><b>Status:</b>
                  <b-badge class="ml-1" v-if="report.queue.status === 'pending'" variant="warning">Pending</b-badge>
                  <b-badge class="ml-1" v-if="report.queue.status === 'accepted'" variant="success">Accepted</b-badge>
                  <b-badge class="ml-1" v-if="report.queue.status === 'failed'" variant="danger">Failed</b-badge>
                  <b-badge class="ml-1" v-if="report.queue.status === 'done'" variant="success">Finished</b-badge>
                </p>
                <p class="mb-0"><b>Last Action:</b> {{report.queue.action}}</p>
                <p class="mb-0"><b>Attempts:</b> {{report.queue.attempts}}</p>
                <p class="mb-0" v-if="report.queue.error_message"><b>Error Details:</b></p>
                <b-form-textarea
                  v-if="report.queue.error_message"
                  v-model="report.queue.error_message"
                  disabled
                  rows="2"
                  max-rows="4"/>
              </div>
              <div v-else>
                <span>Nothing in Queue</span>
              </div>
            </b-col>
          </b-row>
          <p><b>Quarantined</b>:
            <b-badge v-if="report.quarantined" variant="danger">Yes</b-badge>
            <b-badge v-else variant="success">No</b-badge>
          </p>
          <div v-if="report.quarantined || report.was_quarantined">
            <b-form-group
              label="Comment"
              label-for="quarantine-result-comment"
              label-align="left"
              class="mt-3 mb-1">
            </b-form-group>
            <b-form-textarea
              id="quarantine-result-comment"
              v-model="report.comment"
              placeholder="No comment provided"
              class="mb-2"
              disabled
              rows="3"
              max-rows="6"/>
          </div>
          <div v-if="report.quarantined">
            <b-row class="pb-3">
              <b-col cols="12" class="d-flex align-content-between align-items-center" title="BigQuery Table with the ingestion content">
                <div class="text-nowrap pr-2"><b>BigQuery Table</b></div>
                <div class="form-control d-flex align-items-center bigquery-table-input-copy pr-1">
                  <span class="code">{{ report.bigquery_table_id }}</span>
                  <span title="Copy content to clipboard"
                    class="btn btn-info text-white ml-auto copy-btn" @click.stop.prevent="copyTestingCode(report.bigquery_table_id)">
                    <feather type="clipboard"/>
                  </span>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div>
          <div v-if='dryRun'>
            <p><b>Quarantined</b>:
              <b-badge v-if="report.quarantined" variant="danger">Yes</b-badge>
              <b-badge v-else variant="success">No</b-badge>
            </p>
          </div>
          <p><b>System Notes:</b></p>
          <div class="ml-2" v-if="report.report.system_notes.length > 0">
            <ol class="list-group list-group-light list-group-numbered">
              <li class="list-group-item" v-for="(note, id) in report.report.system_notes" :key="id">{{note}}</li>
            </ol>
          </div>
          <div v-else class="pt-0 ml-2">
            <p class="card-text mt-0 mb-0">No notes.</p>
          </div>
        </div>
        <div class="mt-4" v-if="report.report.checks.failed && Object.keys(report.report.checks.failed).length > 0">
          <b-row>
            <b-col>
              <span><b>Failed Checks</b></span>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <b-table tbody-tr-class="table-danger" :items="mapResultToTable(report.report.checks.failed)" :fields="fields">
                <template #cell(operator)="data">
                  {{ beautifyOperator(data.value) }}
                </template>
                <template #cell(value)="data">
                  {{ data.value }}%
                </template>
                <template #cell(expected)="data">
                  {{ data.value }}%
                </template>
              </b-table>
            </b-col>
          </b-row>
        </div>
        <div class="mt-4">
          <b-row>
            <b-col>
              <span>
                <b>Successful Checks</b>
                <b-button v-b-toggle.quarantine-success-checks @click="quarantineSuccessChecksOpenHandler" class="ml-2 btn btn-info btn-sm">
                  {{ quarantineSuccessChecksOpen ? 'Hide' : 'Show' }} Details
                </b-button>
              </span>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <b-collapse id="quarantine-success-checks">
                <b-table tbody-tr-class="table-success" :items="mapResultToTable(report.report.checks.success)" :fields="fields">
                  <template #cell(operator)="data">
                    {{ beautifyOperator(data.value) }}
                  </template>
                  <template #cell(value)="data">
                    {{ data.value }}%
                  </template>
                  <template #cell(expected)="data">
                    {{ data.value }}%
                  </template>
                </b-table>
              </b-collapse>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
    </b-card>
    <div v-if="report.quarantined && !dryRun && report.status === 'pending' && !hideActions && !isWaitingForQueueExecution && !blockActions">
      <b-row class="mt-3">
        <b-col>
          <b>Actions</b>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <!-- how to add a overlay to the button -->
          <b-button variant='success' v-b-tooltip.hover title="Promote this quarantined revision to production"
                    @click="openModalHandler(MODAL_ACTIONS.ACCEPT)" :disabled="loading">
            Accept Ingestion
          </b-button>
          <b-button variant='danger' v-b-tooltip.hover title="Delete this revision from quarantine" class="ml-2"
                    @click="openModalHandler(MODAL_ACTIONS.REJECT)" :disabled="loading">
            Reject Ingestion
          </b-button>
          <b-button variant='info' v-b-modal.modal-1 class="ml-2" v-b-tooltip.hover title="Re-run quarantine checks"
                    @click="openModalHandler(MODAL_ACTIONS.RERUN)" :disabled="loading">
            Re-run Checks
          </b-button>
        </b-col>
      </b-row>
    </div>

    <b-modal ref="quarantine-actions-modal" hide-footer title="Confirm Quarantine Status Change" v-if="!dryRun">
      <div class="d-block text-center">
        <p>{{modal.message}}</p>
        <div class="mt-5" v-if="modal.action !== MODAL_ACTIONS.RERUN">
          <b-form-group
            label="Provide a reason for this action"
            label-for="quarantine-actions-modal-comment"
            label-align="left"
            class="mt-3">
          </b-form-group>
          <b-form-textarea
            id="quarantine-actions-modal-comment"
            v-model="modal.comment"
            placeholder="Reason to proceed"
            rows="3"
            max-rows="6"/>
        </div>
      </div>
      <b-button class="mt-3" variant="danger" block :disabled="loading" @click="acceptModalAction">I'm Sure</b-button>
      <b-button class="mt-2" variant="info" block @click="hideModal">Cancel</b-button>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';

const MODAL_ACTIONS = {
  ACCEPT: 'accept',
  REJECT: 'reject',
  RERUN: 'rerun',
};

export default {
  name: 'Report',
  props: {
    configs: {
      type: Object,
    },
    report: {
      type: Object,
    },
    onboardingClientId: {
      type: Number,
    },
    dryRun: {
      type: Boolean,
      default: false,
    },
    blockActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      MODAL_ACTIONS,
      fields: [
        {
          key: 'label',
          label: 'Label',
        },
        {
          key: 'value',
          label: 'Actual',
        },
        {
          key: 'operator',
          label: 'Operator',
        },
        {
          key: 'expected',
          label: 'Expected',
        },
        {
          key: 'absolute',
          label: 'Absolute',
        },
      ],
      loading: false,
      quarantineSuccessChecksOpen: false,
      hideActions: false,
      modal: {
        message: 'Are you sure you want to promote this quarantined revision to production?',
        action: null,
        comment: null,
      },
    };
  },
  computed: {
    isWaitingForQueueExecution() {
      if (!this.report?.queue?.status) {
        return false;
      }

      return this.report?.queue?.status === 'pending';
    },
  },
  methods: {
    mapResultToTable(checks) {
      const rows = [];

      Object.entries(checks).forEach(([ key, value ]) => {
        rows.push({
          label: this.configs[key].label,
          value: value.value,
          absolute: value.absolute,
          expected: value.expected,
          operator: this.configs[key].operator,
        });
      });

      // sort by label
      rows.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      return rows;
    },
    showModal() {
      this.$refs['quarantine-actions-modal'].show();
    },
    hideModal() {
      this.$refs['quarantine-actions-modal'].hide();
    },
    beautifyOperator(operator) {
      switch (operator) {
        case '>':
          return 'greater than';
        case '>=':
          return 'greater than or equal to';
        case '<':
          return 'less than';
        case '<=':
          return 'less than or equal to';
        case '=':
          return 'equal to';
        case '!=':
          return 'not equal to';
        default:
          return operator;
      }
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm');
    },
    acceptModalAction() {
      if (this.modal.action !== MODAL_ACTIONS.RERUN && (!this.modal.comment || this.modal.comment.length < 10)) {
        this.$noty.error('Please provide a comment to proceed. It should at least be 10 characters long');
        return;
      }

      switch (this.modal.action) {
        case MODAL_ACTIONS.ACCEPT:
          this.acceptQuarantine();
          break;
        case MODAL_ACTIONS.REJECT:
          this.rejectQuarantine();
          break;
        case MODAL_ACTIONS.RERUN:
          this.reRunQuarantine();
          break;
        default:
          break;
      }
      this.hideModal();
    },
    copyTestingCode(value) {
      return navigator.clipboard.writeText(value)
        .then(() => this.$noty.success('Copied to clipboard'));
    },
    openModalHandler(action) {
      switch (action) {
        case MODAL_ACTIONS.ACCEPT:
          this.modal.message = 'Are you sure you want to promote this quarantined revision to production?';
          break;
        case MODAL_ACTIONS.REJECT:
          this.modal.message = 'Are you sure you want to delete this revision from quarantine?';
          break;
        case MODAL_ACTIONS.RERUN:
          this.modal.message = 'Are you sure you want to re-run quarantine checks?';
          break;
        default:
          this.$noty.error(`Invalid action provided: ${action}`);
          return;
      }

      this.modal.comment = null;
      this.modal.action = action;
      this.showModal();
    },
    async acceptQuarantine() {
      try {
        this.loading = true;
        await this.$store.dispatch('Sftp/Eligibility/acceptQuarantinedIngestion', {
          onboarding_client_id: this.onboardingClientId,
          payload: {
            client_id: this.report.client_id,
            revision: this.report.revision,
            comment: this.modal.comment,
          },
        });
        this.$noty.success('Quarantined ingestion scheduled to be promoted to production');
        this.hideActions = true;
        this.$emit('quarantine-action-update');
      } catch (err) {
        console.error(err);
        this.$noty.error(err?.response?.data?.message || 'Something went wrong');
      } finally {
        this.loading = false;
      }
    },
    async rejectQuarantine() {
      try {
        this.loading = true;
        await this.$store.dispatch('Sftp/Eligibility/rejectQuarantinedIngestion', {
          onboarding_client_id: this.onboardingClientId,
          payload: {
            client_id: this.report.client_id,
            revision: this.report.revision,
            comment: this.modal.comment,
          },
        });
        this.$noty.success('Quarantined ingestion scheduled to be deleted');
        this.hideActions = true;
        this.$emit('quarantine-action-update');
      } catch (err) {
        console.error(err);
        this.$noty.error(err?.response?.data?.message || 'Something went wrong');
      } finally {
        this.loading = false;
      }
    },
    async reRunQuarantine() {
      try {
        this.loading = true;
        await this.$store.dispatch('Sftp/Eligibility/retryQuarantinedIngestion', {
          onboarding_client_id: this.onboardingClientId,
          payload: {
            client_id: this.report.client_id,
            revision: this.report.revision,
            comment: this.modal.comment,
          },
        });
        this.$noty.success('Quarantined ingestion scheduled to be evaluated again');
        this.hideActions = true;
        this.$emit('quarantine-action-update');
      } catch (err) {
        console.error(err);
        this.$noty.error(err?.response?.data?.message || 'Something went wrong');
      } finally {
        this.loading = false;
      }
    },
    quarantineSuccessChecksOpenHandler() {
      this.quarantineSuccessChecksOpen = !this.quarantineSuccessChecksOpen;
    },
  },
};
</script>


<style scoped lang="scss">
$default-border-color: #DDDDDD;
$light-gray: #999999;

.bigquery-table-input-copy {
  .title {
    line-height: 18px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: gray;
    margin-right: 6px;
  }

  .code {
    line-height: 24px;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
  }

  .copy-btn {
    padding: 0;
    width: 50px;
    height: 30px;
    line-height: 26px;
  }
}

ul.tester-email-list {
  background-color: white;
  border: solid 1px $default-border-color;
  height: 150px;
  padding: 15px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  li.label {
    border: 1px solid $default-border-color;
    background-color: whitegray;
    padding: 0.3rem;
    height: 24px;
    border-radius: 3px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;

    span {
      padding: 0 3px;
      height: 14px;
      line-height: 14px;
      font-size: 12px;
      font-weight: 500;
      color: gray;
    }

    i {
      color: $light-gray;
      height: 14px;
      line-height: 14px;
      cursor: pointer;
    }
  }

  input.tester-email-input {
    border: none;
    display: inline-block;
    overflow: visible;
    margin: 0;
    padding: 0;
    box-shadow: none;

    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: gray;

    &:focus {
      outline: none;
    }
  }
}
</style>
