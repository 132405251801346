<template>
  <div v-if='quality_check'>
    <b-row cols='12' class='mb-4'>
      <b-col cols='3'>
        <span>Current/Previous File Total Records:</span>
      </b-col>
      <b-col cols='9'>
        <span>{{ quality_check.total_records }} / {{ isNullValue(quality_check.last_file_total_records) ?
          '-' : quality_check.last_file_total_records }}</span>
      </b-col>
    </b-row>
    <b-row cols='12' class='mb-4'>
      <b-col cols='3'>
        <span>Fingerprint:</span>
      </b-col>
      <b-col cols='9'>
        <b-table class='table-bordered' striped hover sticky-header fixed
                 :fields='quality_check.fingerprint.fields'
                 :items='quality_check.fingerprint.items'>
          <template #cell()='data'>
            <i v-if='!isNullValue(data.value.percent) && !isNullValue(data.value.count)'>{{ data.value.percent }}% ({{ data.value.count }})</i>
            <i v-else>-</i>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row cols='12' class='mb-4'>
      <b-col cols='3'>
        <span>Validity Checks:</span>
      </b-col>
      <b-col cols='9'>
        <b-table class='table-bordered' striped hover sticky-header fixed
                 :fields='quality_check.validity_checks.fields'
                 :items='quality_check.validity_checks.items'>
          <template #cell()='data'>
            <i>{{ data.value }}</i>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row cols='12' class='mb-4'>
      <b-col cols='3'>
        <span>Ratio Checks:</span>
      </b-col>
      <b-col cols='9'>
        <b-table class='table-bordered' striped hover sticky-header fixed
                 :fields='quality_check.ratio_checks.fields'
                 :items='quality_check.ratio_checks.items'>
          <template #cell()='data'>
            <i v-if='!isNullValue(data.value)'>{{ data.value }}%</i>
            <i v-else>-</i>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row cols='12' class='mb-4'>
      <b-col cols='3'>
        <span>Fields Coverage:</span>
      </b-col>
      <b-col cols='9'>
        <b-table class='table-bordered' striped hover sticky-header fixed
                 :fields='quality_check.coverage.fields'
                 :items='quality_check.coverage.items'>
          <template #cell()='data'>
            <i>{{ data.value }}</i>
          </template>
          <template #thead-top='data'>
            <b-tr>
              <b-th colspan='3'></b-th>
              <b-th class='text-center' colspan='2'>Empty values</b-th>
            </b-tr>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
  <span v-else>No quality check to display</span>
</template>

<script>

import { isNullOrEmpty } from '@/helpers';

export default {
  name: 'QualityCheck',
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    if (isNullOrEmpty(this.data)) {
      return {
        quality_check: null,
      };
    }
    return {
      quality_check: this.buildQualityCheckData(this.data),
    };
  },
  methods: {
    isNullValue(value) {
      return !!isNullOrEmpty(value);
    },
    buildQualityCheckData(data) {
      return {
        total_records: data?.total_records,
        last_file_total_records: data?.previous_revision?.total_records,
        coverage: this.buildCoverageData(data?.coverage, data?.previous_revision?.coverage),
        fingerprint: this.buildFingerprintData(data),
        validity_checks: this.buildValidityChecksData(data),
        ratio_checks: this.buildRatioChecksData(data),
      };
    },
    buildCoverageData(coverage, previousRevision) {
      const items = [];
      if (isNullOrEmpty(coverage)) {
        return items;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [ f, v ] of Object.entries(coverage)) {
        const previousRevisionValue = previousRevision?.[f];
        const field = {
          column_name: this.fieldNameToDisplay(f),
          total: v.total,
          unique: v.unique,
          current_file_empty: v.empty,
          previous_file_empty: isNullOrEmpty(previousRevisionValue?.empty) ? '-' : previousRevisionValue?.empty,
        };
        items.push(field);
      }

      return {
        fields: [
          { key: 'column_name', label: 'Field name' },
          { key: 'total', label: 'Total' },
          { key: 'unique', label: 'Unique values' },
          { key: 'current_file_empty', label: 'Current file' },
          { key: 'previous_file_empty', label: 'Last file' },
        ],
        items,
      };
    },
    buildFingerprintData(data) {
      return {
        fields: [
          { key: 'new_unique_ids', label: 'New unique ids' },
          { key: 'unique_ids_found_from_last_revision', label: 'Unique ids found from last revision' },
          { key: 'unique_ids_not_found_from_last_revision', label: 'Unique ids not found from last revision' },
        ],
        items: [
          {
            new_unique_ids: {
              count: data?.new_unique_ids,
              percent: this.calculatePercentage(data?.new_unique_ids, data?.total_records),
            },
            unique_ids_found_from_last_revision: {
              count: data?.unique_ids_found_from_last_revision,
              percent: this.calculatePercentage(data?.unique_ids_found_from_last_revision, data?.total_records),
            },
            unique_ids_not_found_from_last_revision: {
              count: data?.unique_ids_not_found_from_last_revision,
              percent: this.calculatePercentage(data?.unique_ids_not_found_from_last_revision, data?.total_records),
            },
          },
        ],
      };
    },
    buildValidityChecksData(data) {
      return {
        fields: [
          { key: 'invalid_gender', label: 'Invalid gender' },
          { key: 'invalid_relationship', label: 'Invalid relationship' },
          { key: 'invalid_state', label: 'Invalid state' },
          { key: 'invalid_email', label: 'Invalid email' },
        ],
        items: [
          {
            invalid_gender: data?.invalid_gender,
            invalid_relationship: data?.invalid_relationship,
            invalid_state: data?.invalid_state,
            invalid_email: data?.invalid_email,
          },
        ],
      };
    },
    buildRatioChecksData(data) {
      return {
        fields: [
          { key: 'male_gender_percentage', label: 'Males on file' },
          { key: 'female_gender_percentage', label: 'Females on file' },
          { key: 'male_gender_change_from_last_revision_percentage', label: 'Change of males' },
          { key: 'female_gender_change_from_last_revision_percentage', label: 'Change of females' },
          { key: 'under_13_years_old_percentage', label: 'Under 13 years old' },
          { key: 'under_18_years_old_percentage', label: 'Between 13 and 18 years old' },
          { key: 'over_18_years_old_percentage', label: 'Over 18 years old' },
          { key: 'bloom_eligible_percentage', label: 'Bloom eligible' },
        ],
        items: [
          {
            male_gender_percentage: this.formatDecimalValues(data?.male_gender_percentage),
            female_gender_percentage: this.formatDecimalValues(data?.female_gender_percentage),
            male_gender_change_from_last_revision_percentage: this.formatDecimalValues(data?.male_gender_change_from_last_revision_percentage),
            female_gender_change_from_last_revision_percentage: this.formatDecimalValues(data?.female_gender_change_from_last_revision_percentage),
            under_13_years_old_percentage: this.formatDecimalValues(data?.under_13_years_old_percentage),
            under_18_years_old_percentage: this.formatDecimalValues(data?.under_18_years_old_percentage),
            over_18_years_old_percentage: this.formatDecimalValues(data?.over_18_years_old_percentage),
            bloom_eligible_percentage: this.formatDecimalValues(data?.bloom_eligible_percentage),
          },
        ],
      };
    },
    fieldNameToDisplay(fieldName) {
      return fieldName.replaceAll('_', ' ').replace(/(^|_)./g, s => s.slice(-1).toUpperCase());
    },
    calculatePercentage(value, total) {
      if (isNullOrEmpty(value) || isNullOrEmpty(total) || total === 0) {
        return null;
      }
      return this.formatDecimalValues((parseInt(value, 10) / parseInt(total, 10)) * 100);
    },
    formatDecimalValues(value) {
      if (isNullOrEmpty(value)) {
        return null;
      }
      return value % 1 !== 0 ? value.toFixed(2) : value;
    },
  },
};
</script>

<style>
</style>
